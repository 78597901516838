<template>
  <MenteeTable
    :tableData="menteeData"
    :total="total"
    :pageResults="currentPage"
    :currentPage="currentPage"
    :next="next"
    :previous="previous"
    @previous-page="getMenteeData"
    @next-page="getMenteeData"
    :title="title"
    :titleText="titleText"
    v-if="!isObjectEmpty(menteeData)"
  />
  <div v-else class="alert-container">
    <YellowAlertItemVue
    :alertTitle="alertTitle"
    :alertText="alertText"
    />
  </div>
  <LoadingItemVue
    v-if="loading"
  />
</template>

<script>

import MenteeRepository from '@/services/MenteeRepository.vue'
import MenteeTable from '../MenteeTable.vue'
import LoadingItemVue from '../general_ui_items/LoadingItem.vue'
import YellowAlertItemVue from '../general_ui_items/YellowAlertItem.vue'


export default {
  name: 'MenteesList',
  async created(){
    this.getMenteeData(this.defaultPage)
  },
  data(){
    return {
      onlineUser: JSON.parse(window.localStorage.getItem("onlineUser")),
      menteeData: {},
      defaultPage: "https://aplication.doctorbrand.com.br/api/getMentees?page=1",
      total:1,
      pageResults:1,
      currentPage:1,
      next:"",
      previous:"",
      loading: false,
      alertTitle: "Nenhum Dado Encontrado",
      alertText: "A busca não entrou nenhum resultado, pode não haver dados ou seu filtro não corresponde a nenhum item",
      title: "Mentorados",
      titleText:"Lista de todos mentorados cadastrados no sistema"
    }
  },
  props: {
  },
  methods:{
    async getMenteeData(page){
      this.loading = true; 
      const menteeRepo = new MenteeRepository();
      try {
        const response = await menteeRepo.getMentee(this.onlineUser.token, page);
        if (response?.status  === 200) {
          this.menteeData = response.data;
          this.total = response.data.total;
          this.pageResults = response.data.per_page;
          this.currentPage = response.data.current_page;

          if (response.next_page_url) {
            this.next = response.next_page_url;
          }else{
            this.next = ""
          }
          if (response.prev_page_url) {
            this.previous = response.prev_page_url;
          }else{
            this.previous = ""
          }
          this.loading = false; 

        }
      } catch (error) {
        console.log(error);
        this.loading = false; 
      }
    },
     isObjectEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
  },
  components:{
    MenteeTable,
    LoadingItemVue,
    YellowAlertItemVue
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.alert-container{
  margin-top: 30px;
}
</style>
