<template>
  <MainTableVue
    :tableData="mentorsData"
    :total="total"
    :pageResults="currentPage"
    :currentPage="currentPage"
    :next="next"
    :previous="previous"
    @previous-page="getMentorsData"
    @next-page="getMentorsData"
    :title="title"
    :titleText="titleText"
  />
  <LoadingItemVue
    v-if="loading"
  />
</template>

<script>

import MentorRepository from '@/services/MentorRepository.vue'
import MainTableVue from '../MainTable.vue'
import LoadingItemVue from '../general_ui_items/LoadingItem.vue'

export default {
  name: 'MentorList',
  async created(){
    this.getMentorsData(this.defaultPage)
  },
  data(){
    return {
      onlineUser: JSON.parse(window.localStorage.getItem("onlineUser")),
      mentorsData: {},
      defaultPage: "https://aplication.doctorbrand.com.br/api/getMentors?page=1",
      total:1,
      pageResults:1,
      currentPage:1,
      next:"",
      previous:"",
      loading: false,
      title: "Mentores",
      titleText:"Lista de todos mentores cadastrados no sistema"
    }
  },
  props: {
  },
  methods:{
    async getMentorsData(page){
      this.loading = true; 
      const mentorRepo = new MentorRepository();
      try {
        const response = await mentorRepo.getMentors(this.onlineUser.token, page);
        if (response?.status  === 200) {
          this.mentorsData = response.data;
          this.total = response.data.total;
          this.pageResults = response.data.per_page;
          this.currentPage = response.data.current_page;
          if (response.data.next_page_url) {
            this.next = response.data.next_page_url;
          }else{
            this.next = ""
          }
          if (response.data.prev_page_url) {
            this.previous = response.data.prev_page_url;
          }else{
            this.previous = ""
          }
          this.loading = false; 

        }
      } catch (error) {
        console.log(error);
        this.loading = false; 
      }
    }
  },
  components:{
    MainTableVue,
    LoadingItemVue
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
