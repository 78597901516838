<template>
    <div class="mt-10 px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-left font-semibold leading-6 text-gray-900">{{title}}</h1>
        <p class="text-left mt-2 text-sm text-gray-700">{{titleText}}</p>
      </div>
    </div>
    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Nome</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">status</th>
                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-for="person in tableData.data" :key="person.id">
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ person.user.name }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ person.user.email }}</td>
                    <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <span class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{{person.status}}</span>
                    </td>
                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <a href="#" class="text-indigo-600 hover:text-indigo-900"
                        >Desativar<span class="sr-only">, {{ person.name }}</span></a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
           <nav class="flex items-center justify-between border-t border-gray-200 bg-white  py-3" aria-label="Pagination">
            <div class="hidden sm:block">
              <p class="text-sm text-gray-700">
                Monstrando página
                {{ ' ' }}
                <span class="font-medium">{{currentPage}}</span>
                {{ ' ' }}
                de
                {{ ' ' }}
                <span class="font-medium">{{pageResults}}</span>
                {{ ' ' }}
                de um total de 
                {{ ' ' }}
                <span class="font-medium">{{total}}</span>
                {{ ' ' }}
                resultados.
              </p>
            </div>
            <div class="flex flex-1 justify-between sm:justify-end">
              <button @click="previousPage" v-if="previous" type="button" class="ml-3 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Anterior</button>
              <button @click="nextPage" v-if="next" type="button" class="ml-3 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Proximo</button>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenteeTable',
  async created(){
  },
  props: {
    tableData:{
      type:Object,
      required:true
    },
    total:{
      type:Number,
      required:true
    },
    pageResults:{
      type:Number,
      required:true
    },
    currentPage:{
      type:Number,
      required:true
    },
    next:{
      type:String,
      required:true
    },
    previous:{
      type:String,
      required:true
    },
    title:{
      type:String,
      required:true
    },
    titleText:{
      type:String,
      required:true
    },
  },
  data(){
    return{
    }
  },
  methods:{
    mainMethod(){
    },
    previousPage(){
      this.$emit('previous-page', this.previous);
    },
    nextPage(){
      this.$emit('next-page', this.next);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
