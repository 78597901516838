<template>
<div class="modalContainer" v-if="openSchedule">
      <!--MODAL STARTS HERE-->
        <div class="modalContent">
          <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-screen-md sm:p-6"> <!-- Modificação aqui -->
                  <UnscheduleDaysVue
                    @mentoria-agendada="onMentoriaAgendada"
                    :mentorIdRaw="mentorId"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!--MODAL STARTS HERE-->
    <div v-if="apiNodata == false"> 
      <button @click.prevent="openScheduleModal()" class="flex items-center justify-center px-4 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <rect x="3" y="4" width="18" height="16" rx="2" ry="2"></rect>
          <line x1="16" y1="2" x2="16" y2="6"></line>
          <line x1="8" y1="2" x2="8" y2="6"></line>
          <line x1="3" y1="10" x2="17" y2="10"></line>
        </svg>
        <span class="ml-1">Definir Agenda de Atendimento</span>
      </button>
        <ul role="list" class="divide-y divide-gray-100">
            <li v-for="mentorship in mentorshipsData.data" :key="mentorship.id" class="flex flex-wrap justify-between gap-x-6 py-5">
                <div class="flex min-w-0 gap-x-4">
                    <img class="h-12 w-12 flex-none rounded-full bg-gray-50" :src="'data:image/png;base64,'+mentorship.mentee.user.image" :alt="mentorship.mentee.user.name">
                    <div class="min-w-0 flex-auto">
                        <p class="text-sm font-semibold leading-6 text-gray-900">{{mentorship.mentee.user.name}}</p>
                        <p class="mt-1 truncate text-xs leading-5 text-gray-500">{{mentorship.mentee.user.email}}</p>
                    </div>
                </div>
                <div class="sm:flex-col sm:items-end">
                  <p v-if="mentorship.status == 1" class="mt-1 text-xs leading-5 text-gray-500">Agendada para dia  {{convertDateTimeToDateString(mentorship.starting_time)}} ás {{extractHoursAndMinutes(mentorship.starting_time)}}</p>
                  <a v-if="mentorship.status == 1" :href="mentorship.link" target="_blank">Acessar Mentoria</a>
                  <p v-if="mentorship.status == 0" class="mt-1 text-xs leading-5 text-gray-500">Aguardando Agendamento Pelo Mentorado</p>
                </div>
            </li>
            <nav class="flex items-center justify-between border-t border-gray-200 bg-white  py-3" aria-label="Pagination">
                <div class="hidden sm:block">
                    <p class="text-sm text-gray-700">
                        Monstrando página
                        {{ ' ' }}
                        <span class="font-medium">{{currentPage}}</span>
                        {{ ' ' }}
                        de
                        {{ ' ' }}
                        <span class="font-medium">{{pageResults}}</span>
                        {{ ' ' }}
                        de um total de 
                        {{ ' ' }}
                        <span class="font-medium">{{total}}</span>
                        {{ ' ' }}
                        resultados.
                    </p>
                </div>
                <div class="flex flex-1 justify-between sm:justify-end">
                    <button @click="previousPage" v-if="previous" type="button" class="ml-3 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Anterior</button>
                    <button @click="nextPage" v-if="next" type="button" class="ml-3 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Proximo</button>
                </div>
            </nav>
        </ul>
    </div>
    <div v-if="apiNodata == true">
        <YellowAlertItemVue
        :alertTitle="alertTitle"
        :alertText="alertText"
        />
    </div>
    <LoadingItemVue
        v-if="loading"
    />
</template>

<script>

import LoadingItemVue from '../general_ui_items/LoadingItem.vue'
import MentorshipRepository from '@/services/MentorshipRepository.vue'
import YellowAlertItemVue from '../general_ui_items/YellowAlertItem.vue'
import UnscheduleDaysVue from '../general_ui_items/UnscheduleDays.vue'


export default {
  name: 'MyMentorshipListMentor',
  async created(){
    this.defaultPage = `https://aplication.doctorbrand.com.br/api/getMyMentorshipsMentor/${this.onlineUser.mentor.id}`;
    this.getMyMentorships(this.defaultPage);
    this.mentorId = this.onlineUser.id
  },
  data(){
    return {
      onlineUser: JSON.parse(window.localStorage.getItem("onlineUser")),
      mentorshipsData:{},
      mentee_id:null,
      mentorId: null,
      openSchedule:false,
      defaultPage: "",
      total:1,
      pageResults:1,
      currentPage:1,
      next:"",
      previous:"",
      loading: false,
      apiNodata:false,
      alertTitle: "",
      alertText: ""
    }
  },
  props: {
  },
  methods:{
    async getMyMentorships(page){
      this.loading = true; 
      const mentorshipsRepo = new MentorshipRepository();
      try {
        const response = await mentorshipsRepo.getMyMentorshipsMentor(this.onlineUser.token, page);
        if (response?.status  === 200) {
          console.log(response)
          this.mentorshipsData = response.data;
          this.total = response.data.total;
          this.pageResults = response.data.last_page;
          this.currentPage = response.data.current_page;
          if (response.data.next_page_url) {
            this.next = response.data.next_page_url;
          }else{
            this.next = ""
          }
          if (response.data.prev_page_url) {
            this.previous = response.data.prev_page_url;
          }else{
            this.previous = ""
          }
          this.loading = false; 

        }
      } catch (error) {
        console.log(error);
        this.alertTitle = "Não ha dados para exibir";
        this.alertText = "Podem não haver dados ou houve um problema com nossa api tente novamente mais tarde"
        this.apiNodata = true;
        this.loading = false; 
      }
    },
    convertDateTimeToDateString(dateTime) {
      // Verifique se dateTime é nulo
      if (dateTime === null) {
        return "";
      }

      // Crie a instância do objeto Date
      const date = new Date(dateTime);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      // Formate os valores do dia, mês e ano em uma string
      return `${day}/${month}/${year}`;
    },
    extractHoursAndMinutes(dateTimeString) {
        if (dateTimeString === null) {
          return "ainda a ser definido";
        }

        // Divida a string em partes com base no espaço em branco
        const dateTimeParts = dateTimeString.split(' ');

        // Obtenha a parte do horário
        const timePart = dateTimeParts[1];

        // Divida a parte do horário em horas e minutos
        const [hours, minutes] = timePart.split(':');

        // Formate os valores de horas e minutos em uma string
        const formattedTime = `${hours}:${minutes}`;

        return formattedTime;
    },
    openScheduleModal(){
      this.openSchedule = true;
    },
    onMentoriaAgendada(){
      this.openSchedule = false;
    }
  },
  components:{
    LoadingItemVue,
    YellowAlertItemVue,
    UnscheduleDaysVue
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
