<template>
<!--MODAL STARTS HERE-->
    <div class="modalContainer" v-if="scheduleSuccessModal">
      <div class="modalContent">
        <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <svg class="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Mentoria Agendanda com Sucesso</h3>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">Você sera redirecionado para pagina com suas Mentorias</p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6">
                  <button type="button" @click="goToMyMentoships" class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Visualizar Mentorias</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!--MODAL ENDS HERE-->
  <div v-if="apiNodata == false">
    <ul role="list" class="divide-y divide-gray-100">
      <li v-for="mentorship in mentorshipsData.data" :key="mentorship.id" class="flex flex-wrap justify-between gap-x-6 py-5">
        <div class="flex min-w-0 gap-x-4">
          <img class="h-12 w-12 flex-none rounded-full bg-gray-50" :src="'data:image/png;base64,'+mentorship.mentor.user.image" :alt="mentorship.mentor.user.name">
          <div class="min-w-0 flex-auto">
            <p class="text-sm font-semibold leading-6 text-gray-900">{{mentorship.mentor.user.name}}</p>
            <p class="mt-1 truncate text-xs leading-5 text-gray-500">{{mentorship.mentor.user.email}}</p>
          </div>
        </div>
        <div class="sm:flex-col sm:items-end">
          <p class="text-sm leading-6 text-gray-900">{{mentorship.mentor.titulo}}</p>
          <p v-if="mentorship.status == 1" class="mt-1 text-xs leading-5 text-gray-500">Agendada para dia  {{convertDateTimeToDateString(mentorship.starting_time)}} ás {{extractHoursAndMinutes(mentorship.starting_time)}}</p>
          <a v-if="mentorship.status == 1" :href="mentorship.link" target="_blank">Acessar Mentoria</a>
        </div>
      </li>
      <nav class="flex items-center justify-between border-t border-gray-200 bg-white  py-3" aria-label="Pagination">
        <div class="hidden sm:block">
          <p class="text-sm text-gray-700">
            Monstrando página
            {{ ' ' }}
            <span class="font-medium">{{currentPage}}</span>
            {{ ' ' }}
            de
            {{ ' ' }}
            <span class="font-medium">{{pageResults}}</span>
            {{ ' ' }}
            de um total de 
            {{ ' ' }}
            <span class="font-medium">{{total}}</span>
            {{ ' ' }}
            resultados.
          </p>
        </div>
        <div class="flex flex-1 justify-between sm:justify-end">
          <button @click="previousPage" v-if="previous" type="button" class="ml-3 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Anterior</button>
          <button @click="nextPage" v-if="next" type="button" class="ml-3 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Proximo</button>
        </div>
      </nav>
    </ul>
  </div>
  <div v-if="apiNodata == true">
    <YellowAlertItemVue
    :alertTitle="alertTitle"
    :alertText="alertText"
    />
  </div>
  <LoadingItemVue
    v-if="loading"
  />
</template>

<script>

import LoadingItemVue from '../general_ui_items/LoadingItem.vue'
import MentorshipRepository from '@/services/MentorshipRepository.vue'
import YellowAlertItemVue from '../general_ui_items/YellowAlertItem.vue'

export default {
  name: 'MyMentorshipList',
  async created(){
    this.defaultPage = `https://aplication.doctorbrand.com.br/api/getMyMentorships/${this.onlineUser.mentee.id}`;
    this.getMyMentorships(this.defaultPage);
  },
  data(){
    return {
      onlineUser: JSON.parse(window.localStorage.getItem("onlineUser")),
      mentorshipsData:{},
      mentorshipScheduleData:{},
      mentee_id:null,
      defaultPage: "",
      total:1,
      pageResults:1,
      currentPage:1,
      next:"",
      previous:"",
      loading: false,
      apiNodata:false,
      alertTitle: "",
      alertText: "",
      open:false,
      scheduleSuccessModal: false
    }
  },
  props: {
  },
  methods:{
    async getMyMentorships(page){
      this.loading = true; 
      const mentorshipsRepo = new MentorshipRepository();
      try {
        const response = await mentorshipsRepo.getMyMentorships(this.onlineUser.token, page);
        if (response?.status  === 200) {
          console.log(response)
          this.mentorshipsData = response.data;
          this.total = response.data.total;
          this.pageResults = response.data.last_page;
          this.currentPage = response.data.current_page;
          if (response.data.next_page_url) {
            this.next = response.data.next_page_url;
          }else{
            this.next = ""
          }
          if (response.data.prev_page_url) {
            this.previous = response.data.prev_page_url;
          }else{
            this.previous = ""
          }
          this.loading = false; 

        }else{
          this.apiNodata = true;
        }
      } catch (error) {
        console.log(error);
        this.alertTitle = "Não ha dados para exibir";
        this.alertText = "Podem não haver dados ou houve um problema com nossa api tente novamente mais tarde"
        this.apiNodata = true;
        this.loading = false; 
      }
    },
   convertDateTimeToDateString(dateTime) {
      // Verifique se dateTime é nulo
      if (dateTime === null) {
        return "";
      }

      // Crie a instância do objeto Date
      const date = new Date(dateTime);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      // Formate os valores do dia, mês e ano em uma string
      return `${day}/${month}/${year}`;
    },
    extractHoursAndMinutes(dateTimeString) {
        if (dateTimeString === null) {
          return "ainda a ser definido";
        }

        // Divida a string em partes com base no espaço em branco
        const dateTimeParts = dateTimeString.split(' ');

        // Obtenha a parte do horário
        const timePart = dateTimeParts[1];

        // Divida a parte do horário em horas e minutos
        const [hours, minutes] = timePart.split(':');

        // Formate os valores de horas e minutos em uma string
        const formattedTime = `${hours}:${minutes}`;

        return formattedTime;
    },
    goToMyMentoships(){
      this.scheduleSuccessModal = false;
      this.getMyMentorships(this.defaultPage);
    }
  },
  components:{
    LoadingItemVue,
    YellowAlertItemVue
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
