<script>

import axios from 'axios';

export default class MenteeRepository{
    
    async getMentee(token,page){
        
        const baseURL = page;
        const headers = {
            'Content-Type':'application/json',
            'Accept':'application/json',
            'Authorization':'Bearer '+token
        };
        const response = await axios.get(baseURL,{headers:headers});
        return response;

    }

    async createMentee(user){
        
        const baseURL = "https://aplication.doctorbrand.com.br/api/createMentee";
        const headers = {
            'Content-Type':'application/json',
            'Accept':'application/json',
        };
        const data = {
            email: user.email,
            password: user.password,
            image: user.image,
            name: user.name
        }
        const response = await axios.post(baseURL, data, {headers:headers});
        return response;

    }

     async UpdateUser(id,data,token){
        
        const baseURL = `https://aplication.doctorbrand.com.br/api/user/${id}`;
        const headers = {
            'Content-Type':'application/json',
            'Accept':'application/json',
            'Authorization':'Bearer '+token
        };
        const response = await axios.put(baseURL, data, {headers:headers});
        return response;

    }

}
</script>
