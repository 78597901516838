<template>
<form @submit.prevent="mentorCreate" class="mt-10 w-4/5 mx-auto">
    <div class="">
     
        <h2 class="text-left font-semibold leading-7 text-gray-900">Informações Pessoais do Mentor</h2>
        <p class="mt-1 text-left text-sm leading-6 text-gray-600">Essa informação será exibida publicamente, então tenha cuidado com o que você compartilha.</p>

      <div class="border-b border-gray-900/10 pb-12">
        <h2 class="text-left font-semibold leading-7 text-gray-900 pt-5">Informações do Mentor</h2>
        <p class="text-left mt-1 text-sm leading-6 text-gray-600">Informações a respeito do perfil do Mentor.</p>

        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="sm:col-span-2">
            <label for="titulo" class="block text-sm font-medium leading-6 text-gray-900">Titulo</label>
            <div class="mt-2">
              <input v-model="mentor.titulo" type="text" name="titulo" id="titulo" autocomplete="titulo" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Descricao</label>
            <div class="mt-2">
              <input v-model="mentor.descricao" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div class="sm:col-span-1">
            <label for="price" class="block text-sm font-medium leading-6 text-gray-900">Valor da Mentoria</label>
            <div class="mt-2">
              <input v-model="mentor.price" type="number" name="price" id="price" autocomplete="price" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="instagram" class="block text-sm font-medium leading-6 text-gray-900">Instagram</label>
            <div class="mt-2">
              <input v-model="mentor.instagram" type="text" name="instagram" id="instagram" autocomplete="instagram" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="linkedin" class="block text-sm font-medium leading-6 text-gray-900">Linkedin</label>
            <div class="mt-2">
              <input v-model="mentor.linkedin" type="text" name="linkedin" id="linkedin" autocomplete="linkedin" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div class="sm:col-span-4">
            <label for="personal_site" class="block text-sm font-medium leading-6 text-gray-900">Site</label>
            <div class="mt-2">
              <input v-model="mentor.personal_site" type="text" name="personal_site" id="personal_site" autocomplete="personal_site" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6 flex items-center justify-end gap-x-6">
      <button type="submit" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Enviar</button>
    </div>
  </form>
</template>

<script>
import MentorRepository from '@/services/MentorRepository.vue'

export default {
  name: 'MentorUpdateForm',
  async created(){
  },
  props: {
    
  },
  data(){
    return{
      user:{

      },
      mentor:{

      },
      onlineUser: JSON.parse(window.localStorage.getItem("onlineUser")),
    }
  },
  methods: {
    async mentorCreate(){
      console.log(this.mentor)
      const mentorRepo = new MentorRepository();
      const user = {
       user:this.user,
       mentor:this.mentor
      }
      try {
        
        const response = await mentorRepo.createMentor(user,this.onlineUser.token);
        if (response?.status === 200) {
          console.log(response)
        }

      } catch (error) {
        this.error = error.response.data.message
      }
    }
  },
  components:{
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
