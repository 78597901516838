<script>

import axios from 'axios';

export default class MentorshipRepository{
    
    async createMentorship(formData,token){
        
        const baseURL = "https://aplication.doctorbrand.com.br/api/createMentorship";
        const headers = {
            'Content-Type':'application/json',
            'Accept':'application/json',
            'Authorization':'Bearer '+token
        };
        const data = formData;
        const response = await axios.post(baseURL, data, {headers:headers});
        return response;

    }
     async getMyMentorships(token,page){
        
        const baseURL = page;
        const headers = {
            'Content-Type':'application/json',
            'Accept':'application/json',
            'Authorization':'Bearer '+token
        };
        const response = await axios.get(baseURL,{headers:headers});
        return response;

    }

    async getMyMentorshipsMentor(token,page){
        
        const baseURL = page;
        const headers = {
            'Content-Type':'application/json',
            'Accept':'application/json',
            'Authorization':'Bearer '+token
        };
        const response = await axios.get(baseURL,{headers:headers});
        return response;

    }
    
    async scheduleMentorship(formData,token){
        
        const baseURL = "https://aplication.doctorbrand.com.br/api/scheduleAmentorship";
        const headers = {
            'Content-Type':'application/json',
            'Accept':'application/json',
            'Authorization':'Bearer '+token
        };
        const data = formData;
        const response = await axios.post(baseURL, data, {headers:headers});
        return response;

    }

    async purchaseAndSchedule(formData,token){
        
        const baseURL = "https://aplication.doctorbrand.com.br/api/mentorship/purchase-and-schedule";
        const headers = {
            'Content-Type':'application/json',
            'Accept':'application/json',
            'Authorization':'Bearer '+token
        };
        const data = formData;
        const response = await axios.post(baseURL, data, {headers:headers});
        return response;

    }

}
</script>
