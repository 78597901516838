<template>
    <div class="inline-block min-w-full py-3 align-middle sm:px-6 lg:px-8 py-10">
    <div class="main-container">
      <div v-if="onlineUser.type != 2">
        <div
          class="mt-4 w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4"
        >
          <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <span v-if="onlineUser.type == 1"
                  class="text-2xl sm:text-3xl leading-none font-bold text-gray-900"
                  >2,340</span
                >
                <span v-if="onlineUser.type == 3"
                  class="text-2xl sm:text-3xl leading-none font-bold text-gray-900"
                  >20</span
                >
                <h3 v-if="onlineUser.type == 1" class="text-base font-normal text-gray-500">
                  RECEBIDO
                </h3>
                <h3 v-if="onlineUser.type == 3" class="text-base font-normal text-gray-500">
                  TOTAL DE USUÁRIOS NO SISTEMA
                </h3>
              </div>
            </div>
          </div>
          <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <span v-if="onlineUser.type == 1"
                  class="text-2xl sm:text-3xl leading-none font-bold text-gray-900"
                  >5,355</span
                >
                <span  v-if="onlineUser.type == 33"
                  class="text-2xl sm:text-3xl leading-none font-bold text-gray-900"
                  >200.000,00</span
                >
                <h3 v-if="onlineUser.type == 1"  class="text-base font-normal text-gray-500">
                  VENDAS
                </h3>
                <h3 v-if="onlineUser.type == 3" class="text-base font-normal text-gray-500">
                  TOTAL EM VENDAS
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chart-container">
        <BarChartVue />
        <LineChartVue />
      </div>
    </div>
  </div>
</template>

<script>
import BarChartVue from '../general_ui_items/BarChart.vue'
import LineChartVue from '../general_ui_items/LineChart.vue'

export default {
  name: 'MenteesList',
  async created(){
  },
  data(){
    return {
      onlineUser: JSON.parse(window.localStorage.getItem("onlineUser")),
      menteeData: {},
      defaultPage: "https://aplication.doctorbrand.com.br/api/getMentees?page=1",
      total:1,
      pageResults:1,
      currentPage:1,
      next:"",
      previous:"",
      loading: false,
    }
  },
  props: {
  },
  methods:{},
  components:{
    LineChartVue,
    BarChartVue
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.alert-container{
  margin-top: 30px;
}
</style>
