<script>

import axios from 'axios';

export default class MentorRepository{
    
    async getMentors(token,page){
        
        const baseURL = page;
        const headers = {
            'Content-Type':'application/json',
            'Accept':'application/json',
            'Authorization':'Bearer '+token
        };
        const response = await axios.get(baseURL,{headers:headers});
        return response;

    }

    async getMentorsWithImg(token,page){
        
        const baseURL = page;
        const headers = {
            'Content-Type':'application/json',
            'Accept':'application/json',
            'Authorization':'Bearer '+token
        };
        const response = await axios.get(baseURL,{headers:headers});
        return response;

    }

    async createMentor(mentor){
        
        const baseURL = "https://aplication.doctorbrand.com.br/api/createMentor";
        const headers = {
            'Content-Type':'application/json',
            'Accept':'application/json'
        };
        const data = mentor;
        const response = await axios.post(baseURL, data, {headers:headers});
        return response;

    }

    async getMentorById(token,id){
        
        const baseURL = `https://aplication.doctorbrand.com.br/api/getMentorById/${id}`;
        const headers = {
            'Content-Type':'application/json',
            'Accept':'application/json',
            'Authorization':'Bearer '+token
        };
        const response = await axios.get(baseURL,{headers:headers});
        return response;

    }
    async getMentorUnavailableHours(token,id){
        
        const baseURL = `https://aplication.doctorbrand.com.br/api/mentorships/unavailable-hours/${id}`;
        const headers = {
            'Content-Type':'application/json',
            'Accept':'application/json',
            'Authorization':'Bearer '+token
        };
        const response = await axios.get(baseURL,{headers:headers});
        return response;

    }

}
</script>
