import axios from 'axios';

export function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

// Função para verificar se um dia é sábado
export function isSaturday(date) {
  return date.getDay() === 6;
}

// Função para verificar se um dia é domingo
export function isSunday(date) {
  return date.getDay() === 0;
}

// Função para verificar se um dia é feriado (usando a API de feriados do Nageru)
export async function isHoliday(date) {
  try {
    const response = await axios.get(`https://date.nager.at/api/v3/publicholidays/${date.getFullYear()}/BR`);
    const holidays = response.data;

    const formattedDate = date.toISOString().split('T')[0];
    return holidays.some(holiday => holiday.date === formattedDate);
  } catch (error) {
    console.error('Erro ao obter feriados:', error);
    return false; // Em caso de erro, consideramos que não é feriado
  }
}