<template>
<form class="mt-10 w-4/5 mx-auto">
    <div class="">
      <div class="border-b border-gray-900/10 pb-12">
        <h2 class="text-left font-semibold leading-7 text-gray-900">Cadastro</h2>
        <p class="mt-1 text-left text-sm leading-6 text-gray-600">Essa informação será exibida publicamente, então tenha cuidado com o que você compartilha.</p>

        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="sm:col-span-4">
            <label for="username" class="text-left block text-sm font-medium leading-6 text-gray-900">Nome</label>
            <div class="mt-2">
              <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input v-model="user.name" type="text" name="username" id="username" autocomplete="username" class="text-left block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" placeholder="Nome" />
              </div>
            </div>
          </div>
          <div class="sm:col-span-4">
            <label for="email" class="text-left block text-sm font-medium leading-6 text-gray-900">Email</label>
            <div class="mt-2">
              <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input v-model="user.email" type="email" name="email" id="email" autocomplete="email" class="text-left block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" placeholder="E-mail" />
              </div>
            </div>
          </div>
          <div class="sm:col-span-4">
            <label for="password" class="text-left block text-sm font-medium leading-6 text-gray-900">Senha</label>
            <div class="mt-2">
              <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input v-model="user.password" type="password" name="password" id="password" autocomplete="password" class="text-left block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" placeholder="Senha" />
              </div>
            </div>
          </div>

          <div class="col-span-full">
            <label for="photo" class="block text-sm font-medium leading-6 text-gray-900 text-left">Foto</label>
            <div class="mt-2 flex items-center gap-x-3">
              <UserCircleIcon class="h-12 w-12 text-gray-300" aria-hidden="true" />
              <input @change="onFileSelected" type="file" class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              <p class="text-xs leading-5 text-gray-600">PNG, JPG, GIF até 1MB</p>
            </div>
          </div>

          <div class="text-left col-span-full">
            <a class="" href="https://tinypng.com/" target="_blank">Comprimir imagem com TinyPNG</a><br>
            <a class="" href="https://compressor.io/" target="_blank">Comprimir imagem com Compressor.io</a><br>
            <!-- Adicione outros links aqui -->
          </div>
        </div>
      </div>
    </div>
    <div class="mt-6 flex items-center justify-end gap-x-6">
      <button @click="menteeCreate" type="button" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Enviar</button>
    </div>
  </form>
  <LoadingItemVue
        v-if="loading"
    />
</template>

<script>
import { UserCircleIcon } from '@heroicons/vue/24/solid'
import MenteeRepository from '@/services/MenteeRepository.vue'
import LoadingItemVue from './general_ui_items/LoadingItem.vue'

export default {
  name: 'MenteeRegistrationForm',
  async created(){
  },
  props: {
    
  },
  data(){
    return{
      user:{

      },
      onlineUser: JSON.parse(window.localStorage.getItem("onlineUser")),
      loading: false
    }
  },
  methods: {
    onFileSelected(event) {
      const file = event.target.files[0];
      
      if (file) {
        if (file.size > 1024 * 1024) {
          alert("A imagem deve ter no máximo 1 MB.");
          return;
        }

        // Verifica o tipo da imagem
        const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
        if (!allowedTypes.includes(file.type)) {
          alert("A imagem deve ser do tipo PNG, JPG ou GIF.");
          return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const base64String = reader.result.split(',')[1];
          this.user.image = base64String;
        };
      }
    },
    async menteeCreate(){

      this.loading = true; 
      const menteeRepo = new MenteeRepository();
      const user = {
        email:this.user.email,
        name: this.user.name,
        password:this.user.password,
        image:this.user.image
      }
      try {
        
        const response = await menteeRepo.createMentee(user,this.onlineUser.token);
        if (response?.status === 200) {
           this.loading = false; 
        }

      } catch (error) {
        this.error = error
         this.loading = false; 
      }
    }
  },
  components:{
    UserCircleIcon,
    LoadingItemVue
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
