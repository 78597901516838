<template>
  <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <img class="mx-auto h-10 w-auto" src="https://uploads-ssl.webflow.com/60607b25535705ee2df91fbf/606083395c9d2b42f33c3309_logotipo.svg" alt="DoctorBrand" />
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Entre em sua conta</h2>
    </div>
    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form @submit.prevent="login" class="space-y-6">
        <div>
          <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email</label>
          <div class="mt-2">
            <input v-model="email" id="email" name="email" type="email" autocomplete="email" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
          </div>
        </div>

        <div>
          <div class="flex items-center justify-between">
            <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Senha</label>
            <div class="text-sm">
              <a href="#" class="font-semibold text-indigo-600 hover:text-indigo-500">Esqueceu sua senha?</a>
            </div>
          </div>
          <div class="mt-2">
            <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
          </div>
        </div>

        <div>
          <button type="submit" class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Entrar</button>
        </div>
      </form>

      <p class="mt-10 text-center text-sm text-gray-500">
        Não possui cadastro?
        {{ ' ' }}
        <a href="/menteeSignUp" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Começe agora se cadastrando aqui</a>
      </p>
      <p class="mt-10 text-center text-sm text-gray-500">
        Deseja Ser Mentor em nossa plataforma?
        {{ ' ' }}
        <a href="/mentorSignUp" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Começe agora se cadastrando aqui</a>
      </p>
    </div>
  </div>
  <LoadingItemVue
        v-if="loading"
    />
</template>

<script>
import LoginRepository from '@/services/LoginRepository.vue';
import LoadingItemVue from './general_ui_items/LoadingItem.vue'

export default {
  name: 'LoginForm',
  data(){
    return {
      email: '',
      password: '',
      loading: false,
    }
  },
  props: {},
  components: {
    LoadingItemVue
  },
  methods:{
    async login(){
       this.loading = true; 
      const userLogin = new LoginRepository();
      const user = {
        email : this.email,
        password : this.password
      }
      try {
        const response = await userLogin.doLogin(user);
        if (response?.status === 200) {
           this.loading = false; 
          const onlineUser = response.data;
          window.localStorage.setItem("onlineUser", JSON.stringify(onlineUser));
          this.$router.push({ path: '/dashboard'})
        }
      } catch (error) {
         this.loading = false; 
        this.error = error.response.data.message
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>