<template>
  <LoadingItemVue
    v-if="loading"
  />
  <!--MODAL STARTS HERE-->
    <div class="modalContainer" v-if="open">
      <div class="modalContent">
        <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <svg class="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Pagamento Concluido com Sucesso</h3>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">Você sera redirecionado para pagina com suas Mentorias</p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6">
                  <button type="button" @click="goToMyMentoships" class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Visualizar Mentorias</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!--MODAL ENDS HERE-->
<main class="lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden">
  <!-- Mobile order summary -->
  <section aria-labelledby="order-heading" class="bg-gray-50 px-4 py-6 sm:px-6 lg:hidden">
    <div class="mx-auto max-w-lg">
      <div class="flex items-center justify-between">
        <h2 id="order-heading" class="text-lg font-medium text-gray-900">Seu Pedido</h2>
      </div>

      <div id="disclosure-1">
        <ul role="list" class="divide-y divide-gray-200 border-b border-gray-200">
          <li class="flex space-x-6 py-6">
            <img :src="'data:image/png;base64,'+mentorImg" :alt="mentorUser.name" class="h-40 w-40 flex-none rounded-md bg-gray-200 object-cover object-center">
            <div class="flex flex-col justify-between space-y-4">
              <div class="space-y-1 text-sm font-medium">
                <h3 class="text-gray-900">{{mentorUser.name}}</h3>
                <p class="text-gray-900">{{mentorData.price}} BRL</p>
                <p class="text-gray-500">{{mentorData.descricao}}</p>
                <p class="text-gray-500">{{mentorData.titulo}}</p>
              </div>
            </div>
          </li>
        </ul>
        <form class="mt-10">
          <label for="discount-code-mobile" class="block text-sm font-medium text-gray-700">Código de desconto</label>
          <div class="mt-1 flex space-x-4">
            <input type="text" id="discount-code-mobile" name="discount-code-mobile" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
            <button type="button" class="rounded-md bg-gray-200 px-4 text-sm font-medium text-gray-600 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50">Aplicar</button>
          </div>
        </form>

        <dl class="mt-10 space-y-6 text-sm font-medium text-gray-500">
        <div class="flex justify-between">
          <dt>Subtotal</dt>
          <dd class="text-gray-900">{{mentorData.price}} BRL</dd>
        </div>
        <div class="flex justify-between">
          <dt>Taxas</dt>
          <dd class="text-gray-900">0.00</dd>
        </div>
        <div class="flex justify-between">
          <dt>Descontos</dt>
          <dd class="text-gray-900">0.00</dd>
        </div>
        <div class="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
          <dt>Total</dt>
          <dd class="text-base">{{mentorData.price}} BRL</dd>
        </div>
      </dl>
      </div>

      <p class="mt-6 flex items-center justify-between border-t border-gray-200 pt-6 text-sm font-medium text-gray-900">
        <span class="text-base">Total</span>
        <span class="text-base">{{mentorData.price}} BRL</span>
      </p>
    </div>
  </section>

  <!-- Order summary -->
  <section aria-labelledby="summary-heading" class="hidden w-full max-w-md flex-col bg-gray-50 lg:flex">
    <h2 id="summary-heading" class="sr-only">Resumo da compra</h2>

    <ul role="list" class="flex-auto divide-y divide-gray-200 overflow-y-auto px-6">
      <li class="flex space-x-6 py-6">
        <img :src="'data:image/png;base64,'+mentorImg" :alt="mentorUser.name" class="h-40 w-40 flex-none rounded-md bg-gray-200 object-cover object-center">
        <div class="flex flex-col justify-between space-y-4">
          <div class="space-y-1 text-sm font-medium">
            <h3 class="text-gray-900">{{mentorUser.name}}</h3>
            <p class="text-gray-900">{{mentorData.price}} BRL</p>
            <p class="text-gray-500">{{mentorData.descricao}}</p>
            <p class="text-gray-500">{{mentorData.titulo}}</p>
          </div>
        </div>
      </li>
    </ul>
    <div class="sticky bottom-0 flex-none border-t border-gray-200 bg-gray-50 p-6">
      <form>
        <label for="discount-code" class="block text-sm font-medium text-gray-700">Código de desconto</label>
        <div class="mt-1 flex space-x-4">
          <input type="text" id="discount-code" name="discount-code" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
          <button type="button" class="rounded-md bg-gray-200 px-4 text-sm font-medium text-gray-600 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50">Aplicar</button>
        </div>
      </form>

      <dl class="mt-10 space-y-6 text-sm font-medium text-gray-500">
        <div class="flex justify-between">
          <dt>Subtotal</dt>
          <dd class="text-gray-900">{{mentorData.price}} BRL</dd>
        </div>
        <div class="flex justify-between">
          <dt>Taxas</dt>
          <dd class="text-gray-900">0.00</dd>
        </div>
        <div class="flex justify-between">
          <dt>Descontos</dt>
          <dd class="text-gray-900">0.00</dd>
        </div>
        <div class="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
          <dt>Total</dt>
          <dd class="text-base">{{mentorData.price}} BRL</dd>
        </div>
      </dl>
    </div>
  </section>

  <!-- Checkout form -->
  <section aria-labelledby="payment-heading" class="flex-auto overflow-y-auto px-4 pb-16 pt-12 sm:px-6 sm:pt-16 lg:px-8 lg:pb-24 lg:pt-0">
    <h2 id="payment-heading" class="sr-only">Detalhes de Pagamentro</h2>

    <div class="mx-auto max-w-lg lg:pt-16">
      <form @submit.prevent="submitForm" class="mt-6">
        <div class="grid grid-cols-12 gap-x-4 gap-y-6">
          <div class="col-span-full">
            <div class="scheduleContainer flex">
              <button @click.prevent="openScheduleModal()" :class="{ 'bg-red-500': !isDateSelected, 'bg-blue-500': isDateSelected }" class="flex items-center justify-center px-4 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <rect x="3" y="4" width="18" height="16" rx="2" ry="2"></rect>
                  <line x1="16" y1="2" x2="16" y2="6"></line>
                  <line x1="8" y1="2" x2="8" y2="6"></line>
                  <line x1="3" y1="10" x2="17" y2="10"></line>
                </svg>
                <span class="ml-1">Agendar Data Mentoria</span>
              </button>
              <!--MODAL STARTS HERE-->
              <div class="modalContainer" v-if="openSchedule">
                <div class="modalContent">
                  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-screen-md sm:p-6"> <!-- Modificação aqui -->
                          <ScheduleCalendarVue
                            @mentoria-agendada="onMentoriaAgendada"
                            :mentorIdRaw="mentorId"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <!--MODAL STARTS HERE-->
            </div>
          </div>

          <div class="col-span-full">
            <label for="email-address" class="block text-sm font-medium text-gray-700">Endereço de email</label>
            <div class="mt-1">
              <input v-model="formData.email" type="email" id="email-address" name="email-address" autocomplete="email" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" required>
            </div>
          </div>

          <div class="col-span-full">
            <label for="name-on-card" class="block text-sm font-medium text-gray-700">Nome impresso no cartão</label>
            <div class="mt-1">
              <input v-model="formData.name_on_card" type="text" id="name-on-card" name="name-on-card" autocomplete="cc-name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" required>
            </div>
          </div>

          <div class="col-span-full">
            <label for="card-number" class="block text-sm font-medium text-gray-700">Numero do Cartao</label>
            <div class="mt-1">
              <input v-model="formData.card_number"  @input="detectarBandeira" type="number" id="card-number" name="card-number" autocomplete="cc-number" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" required>
            </div>
          </div>

          <div class="col-span-8 sm:col-span-9">
            <label for="expiration-date" class="block text-sm font-medium text-gray-700">Vencimento (M/A)</label>
            <div class="mt-1">
              <input v-model="formData.expiration_date" type="month" name="expiration-date" id="expiration-date" autocomplete="cc-exp" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" required>
            </div>
          </div>

          <div class="col-span-4 sm:col-span-3">
            <label for="cvc" class="block text-sm font-medium text-gray-700">CVC</label>
            <div class="mt-1">
              <input v-model="formData.cvc" type="number" name="cvc" id="cvc" autocomplete="csc" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" required>
            </div>
          </div>

          <div class="col-span-full">
            <label for="address" class="block text-sm font-medium text-gray-700">Endereço de cobrança</label>
            <div class="mt-1">
              <input v-model="formData.address" type="text" id="address" name="address" autocomplete="street-address" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" required>
            </div>
          </div>

          <div class="col-span-full sm:col-span-4">
            <label for="city" class="block text-sm font-medium text-gray-700">Cidade</label>
            <div class="mt-1">
              <input v-model="formData.address_level2" type="text" id="city" name="city" autocomplete="address-level2" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" required>
            </div>
          </div>

          <div class="col-span-full sm:col-span-4">
            <label for="region" class="block text-sm font-medium text-gray-700">Estado</label>
            <div class="mt-1">
              <input v-model="formData.region" type="text" id="region" name="region" autocomplete="address-level1" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" required>
            </div>
          </div>

          <div class="col-span-full sm:col-span-4">
            <label for="postal-code" class="block text-sm font-medium text-gray-700">CEP</label>
            <div class="mt-1">
              <input v-model="formData.postal_code" type="number" id="postal-code" name="postal-code" autocomplete="postal-code" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" required>
            </div>
          </div>
        </div>

        <button @click="submitFormsubmitForm" type="submit" class="mt-6 w-full rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Pagar {{mentorData.price}} BRL</button>
        
        <p class="mt-6 flex justify-center text-sm font-medium text-gray-500">
          <svg class="mr-1.5 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
          </svg>
          Seus dados de Pagamentos não são guarados em nosso sistema.
        </p>
      </form>
    </div>
  </section>
</main>

</template>

<script>

import MentorRepository from '@/services/MentorRepository.vue'
import LoadingItemVue from '../general_ui_items/LoadingItem.vue'
import MentorshipRepository from '@/services/MentorshipRepository.vue'
import ScheduleCalendarVue from '../general_ui_items/ScheduleCalendar.vue'

export default {
  name: 'MentorshipSchedule',
  async created(){
	this.getMentorsData();
  },
  props: {
    
  },
  data(){
    return{
      onlineUser: JSON.parse(window.localStorage.getItem("onlineUser")),
      mentorData: {},
      isDateSelected: false,
      defaultPage: "https://aplication.doctorbrand.com.br/api/getMentorsWithImg?page=1",
      mentorId: this.$route.params.id,
      mentorUser:{},
      mentorImg:"",
      loading: false,
      openSchedule:false,
      paymentDetails:{},
      bandeira: '',
      minDate: '',
      formData:{},
      formErrors:[],
      open: false
    }
  },
  methods: {
   async getMentorsData(){
      this.loading = true;
      const mentorRepo = new MentorRepository();
      try {
        const response = await mentorRepo.getMentorById(this.onlineUser.token,this.mentorId);
        if (response?.status  === 200) {
          console.log(response.data);
          this.mentorData = response.data;
          this.mentorUser = response.data.user;
          this.mentorImg = response.data.user.image;
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false; 
      }
    },
    detectarBandeira(event) {
		const numeroCartao = event.target.value;
		// Implemente a lógica para identificar a bandeira aqui e atribua a bandeira à propriedade 'bandeira'
		// Por exemplo:
      if (/^4/.test(numeroCartao)) {
        this.bandeira = 'Visa';
        console.log(this.bandeira)
      } else if (/^5[1-5]/.test(numeroCartao)) {
        this.bandeira = 'MasterCard';
        console.log(this.bandeira);
      } else {
        this.bandeira = ''; // Não identificado
        console.log(this.bandeira);
      }
    },
    async submitForm(e){
      e.preventDefault();
      this.loading = true;
      this.formData.mentor_id = this.mentorId;
      this.formData.mentee_id = this.onlineUser.mentee.id;
      this.formData.price = this.mentorData.price;

       if (!this.formData.date) {
        // Se não estiver preenchido, exibir mensagem e interromper o envio do formulário
        alert('A data é obrigatória.');
        this.loading = false;
        return;
      }

      const mentorshipRepo = new MentorshipRepository();
      
      try {
        
        const response = await mentorshipRepo.purchaseAndSchedule(this.formData,this.onlineUser.token);
        if (response?.status === 200) {
          this.loading = false;
          this.open = true;
        }else{
          this.loading = false;
        }

      } catch (error) {
        this.loading = false;
        console.log(error)
      }
      
    },
    onMentoriaAgendada(date, hour) {
      this.formData.date = date+" "+hour;
      this.isDateSelected = true;
      this.openSchedule = false;
      console.log(this.formData)
    },
    goToMyMentoships(){
      this.$router.push({ path: '/mentorship/myMentorships'})
    },
    openScheduleModal(){
      console.log('ta aqui')
      this.openSchedule = true;
    }
  },
  mounted() {
   // Obter a data atual
    const currentDate = new Date();

    // Inicializar uma lista de datas válidas
    const validDates = [];

    // Definir o número de dias a serem verificados (365 para um ano completo)
    const daysToCheck = 365;

    // Variáveis para ano, mês e dia
    let year, month, day;

    // Iterar pelos próximos dias para encontrar as datas válidas
    for (let i = 1; i <= daysToCheck; i++) {
      const currentDay = new Date(currentDate);
      currentDay.setDate(currentDate.getDate() + i);

      // Verificar se o dia da semana não é sábado (6) nem domingo (0)
      if (currentDay.getDay() !== 6 && currentDay.getDay() !== 0) {
        // Formatar a data no formato "YYYY-MM-DD"
        year = currentDay.getFullYear();
        month = String(currentDay.getMonth() + 1).padStart(2, '0');
        day = String(currentDay.getDate()).padStart(2, '0');
        validDates.push(`${year}-${month}-${day}`);
      }
    }

    // Definir a data mínima como a primeira data válida
    this.minDate = validDates[0];
  },
  components:{
    LoadingItemVue,
    ScheduleCalendarVue
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
