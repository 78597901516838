<template>
  <div class="calendar-container">
    <h2>Calendário de Agendamento</h2>
    <div v-for="day in availableDays" :key="day.date" class="day-container">
      <h3 class="day-header">{{ day.date }}</h3>
      <div class="button-group">
        <button @click="selectAllHours(day)" :disabled="!day.isAvailable || day.allHoursSelected" class="action-button">Selecionar Todos</button>
        <button @click="deselectAllHours(day)" :disabled="!day.isAvailable || !day.allHoursSelected" class="action-button">Desselecionar Todos</button>
      </div>
      <ul class="hour-list">
        <li v-for="hour in day.availableHours" :key="hour" class="hour-item">
          {{ hour }} 
          <button @click="agendarMentoria(day.date, hour)" :disabled="day.unavailableHours.includes(hour)" class="schedule-button">
            Desabilitar
          </button>
        </li>
      </ul>
      <p v-if="day.unavailableHours.length" class="unavailable-info">
        Horários Indisponíveis para {{ day.date }}: {{ day.unavailableHours.join(', ') }}
      </p>
    </div>
  </div>
</template>

<script>
import { addDays, isSaturday, isSunday, isHoliday } from '@/utils/date-utils';
import MentorRepository from '@/services/MentorRepository.vue';

export default {
  props: {
    mentorIdRaw: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      mentorId: this.mentorIdRaw, // Substitua pelo ID real do mentor
      availableDays: [],
      onlineUser: JSON.parse(window.localStorage.getItem("onlineUser")),
    };
  },
  created() {
    this.fetchAvailableDays();
  },
  methods: {
    async fetchAvailableDays() {
      try {
        const today = new Date();
        const endDate = addDays(today, 30);

        const mentorUnavailableHoursResponse = await this.getMentorUnavailableHours();
        const mentorUnavailableHours = mentorUnavailableHoursResponse.unavailableHours;

        const availableDays = [];
        let currentDate = today;

        while (currentDate < endDate) {
          if (!isSaturday(currentDate) && !isSunday(currentDate) && !(await isHoliday(currentDate, 'BR'))) {
            const formattedDate = currentDate.toISOString().split('T')[0];
            
            // Verificar se há horas indisponíveis para o dia atual
            const unavailableHoursForDay = mentorUnavailableHours.filter(hour => {
              const unavailableDate = hour.split(' ')[0];
              return unavailableDate === formattedDate;
            });

            const availableHours = this.generateAvailableHours(unavailableHoursForDay);
            
            const horasDeCadaItem = unavailableHoursForDay.map(horaCompleta => {
              const hora = horaCompleta.split(" ")[1];
              const horaFormatada = hora.slice(0, 5);
              return horaFormatada;
            });
            const novoArray = availableHours.filter(itemA => !horasDeCadaItem.includes(itemA));

            availableDays.push({
              date: formattedDate,
              availableHours: novoArray,
              unavailableHours: horasDeCadaItem,
              allHoursSelected: false, // Novo
              isAvailable: true,
            });
          }
          currentDate = addDays(currentDate, 1);
        }

        this.availableDays = availableDays;
      } catch (error) {
        console.error('Erro ao buscar dias disponíveis:', error);
      }
    },

    generateAvailableHours(unavailableHoursForDay) {
      const allHours = ['08:00', '09:00', '10:00', '11:00', '14:00', '15:00', '16:00', '17:00'];
      return allHours.filter(hour => !unavailableHoursForDay.includes(hour));
    },

    async getMentorUnavailableHours() {
      const mentorRepo = new MentorRepository();
      try {
        const response = await mentorRepo.getMentorUnavailableHours(this.onlineUser.token,  this.mentorId);
        if (response?.status === 200) {
          console.log(response.data);
          return response.data;
        } else {
          throw new Error('Erro ao obter as horas indisponíveis do mentor');
        }
      } catch (error) {
        console.error('Erro ao buscar horas indisponíveis do mentor:', error);
        return [];
      }
    },

    agendarMentoria(date, hour) {
      this.$emit('mentoria-agendada', date, hour);
    },

    selectAllHours(day) {
        console.log(day)
      day.allHoursSelected = true;
    },

    deselectAllHours(day) {
      day.allHoursSelected = false;
    },
  },
};
</script>

<style scoped>
/* Estilos do componente */
.calendar-container {
  max-width: 600px;
  margin: auto;
  font-family: 'Arial', sans-serif;
}

.day-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
}

.day-header {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #333;
}

.hour-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.hour-item {
  margin: 5px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.schedule-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.unavailable-info {
  color: #d9534f;
  margin-top: 10px;
}

.button-group {
  margin-bottom: 10px;
}

.action-button {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.action-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Adicione mais estilos conforme necessário */
</style>
