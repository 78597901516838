<template>
    <div>
      <apexchart width="500" type="bar" :options="options" :series="series"></apexchart>
    </div>
</template>
<script>


export default {
  
  name:'BarChart',
  data: function() {
    return {
      options: {
        chart: {
          id: 'vuechart-example'
        },
        xaxis: {
          categories: ["2023-01", "2023-02", "2023-03", "2023-04", "2023-05", "2023-06", "2023-07", "2023-08", "2023-09","2023-10", "2023-11"]
        }
      },
      series: [{
        name: 'teste',
        data: [108, 108, 108, 97, 97, 85, 85, 73, 88, 68, 47]
      }]
    }
  },
};
</script>