<template>
    <div class="bg-white py-5 sm:py-5">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:mx-0">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Nosso Time de Mentores</h2>
            <p class="mt-6 text-lg leading-8 text-gray-600">Somos um grupo dinâmico de indivíduos apaixonados pelo que fazemos e dedicados a entregar os melhores resultados 
                para nossos clientes. Como mentores, estamos aqui para compartilhar nossos conhecimentos e experiências com você. Nossa equipe é composta por especialistas 
                em diversas áreas, prontos para orientá-lo em seu crescimento profissional e pessoal.
            </p>
        </div>
        <ul role="list" class="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <li v-for="person in mentorsData.data" :key="person.id">
                <img class="aspect-[3/2] w-full rounded-2xl object-fill" :src="'data:image/png;base64,'+person.user.image" :alt="person.user.name" />
                <h3 class="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{{ person.user.name }}</h3>
                <p class="text-base leading-7 text-gray-600">{{ person.user.titulo }}</p>
                <ul role="list" class="mt-6 flex gap-x-6">
                    <li>
                        <a :href="person.instagram" class="text-gray-400 hover:text-gray-500" target="_blank">
                        <span class="sr-only">Twitter</span>
                        <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                        </svg>
                        </a>
                    </li>
                    
                    <li>
                        <a :href="person.linkedin" class="text-gray-400 hover:text-gray-500" target="_blank">
                        <span class="sr-only">LinkedIn</span>
                        <svg class="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clip-rule="evenodd" />
                        </svg>
                        </a>
                    </li>

                    <li>
                        <a :href="person.personal_site" class="text-gray-400 hover:text-gray-500" target="_blank">
                        <span class="sr-only">Site</span>
                        <svg class="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 122.88 91.26">
                            <path d="M14.85,45.46h6.65l2.39,12.65l3.5-12.65h6.6l3.53,12.67l2.41-12.67h6.62l-5,22.68h-6.87L30.7,53.86 l-3.95,14.28h-6.86L14.85,45.46L14.85,45.46z M8.32,0h106.24c4.58,0,8.32,3.74,8.32,8.32v74.62c0,4.57-3.74,8.32-8.32,8.32H8.32 C3.74,91.26,0,87.51,0,82.94V8.32C0,3.74,3.74,0,8.32,0L8.32,0z M117.97,23.29H5.29v60.46c0,0.64,0.25,1.2,0.67,1.63 c0.42,0.42,0.99,0.67,1.63,0.67h108.04c0.64,0,1.2-0.25,1.63-0.67c0.43-0.43,0.67-0.99,0.67-1.63V23.29H117.97L117.97,23.29z M106.64,9.35c2.27,0,4.11,1.84,4.11,4.11s-1.84,4.11-4.11,4.11c-2.27,0-4.11-1.84-4.11-4.11S104.38,9.35,106.64,9.35L106.64,9.35z M78.8,9.35c2.27,0,4.11,1.84,4.11,4.11s-1.84,4.11-4.11,4.11c-2.27,0-4.11-1.84-4.11-4.11S76.53,9.35,78.8,9.35L78.8,9.35z M92.72,9.35c2.27,0,4.11,1.84,4.11,4.11s-1.84,4.11-4.11,4.11c-2.27,0-4.11-1.84-4.11-4.11S90.45,9.35,92.72,9.35L92.72,9.35z M78.3,45.46h6.65l2.39,12.65l3.5-12.65h6.6l3.53,12.67l2.41-12.67h6.62l-5,22.68h-6.87l-3.98-14.28L90.2,68.14h-6.86L78.3,45.46 L78.3,45.46z M46.58,45.46h6.65l2.39,12.65l3.5-12.65h6.6l3.53,12.67l2.41-12.67h6.62l-5,22.68h-6.87l-3.98-14.28l-3.95,14.28 h-6.86L46.58,45.46L46.58,45.46z"/>
                        </svg>
                        </a>
                    </li>
                </ul>
                <div class="mx-auto max-w-xs px-8">
                    <p class="mt-6 flex items-baseline justify-center gap-x-2">
                        <span class="text-3xl font-bold tracking-tight text-gray-900">${{person.price}}</span>
                        <span class="text-sm font-semibold leading-6 tracking-wide text-gray-600">BRL</span>
                    </p>
                    <router-link :to="'/mentorship/schedule/'+person.id">
                     <a href="#" class="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Agendar Mentoria</a>
                    </router-link>
                </div>
            </li>
        </ul>
    </div>
    <nav class="flex items-center justify-between border-t border-gray-200 bg-white  py-3 mt-10" aria-label="Pagination">
    <div class="hidden sm:block">
        <p class="text-sm text-gray-700">
        Monstrando página
        {{ ' ' }}
        <span class="font-medium">{{currentPage}}</span>
        {{ ' ' }}
        de
        {{ ' ' }}
        <span class="font-medium">{{pageResults}}</span>
        {{ ' ' }}
        de um total de 
        {{ ' ' }}
        <span class="font-medium">{{total}}</span>
        {{ ' ' }}
        resultados.
        </p>
    </div>
    <div class="flex flex-1 justify-between sm:justify-end">
        <button v-if="previous" @click="getMentorsData(previous)"  type="button" class="ml-3 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Anterior</button>
        <button v-if="next" @click="getMentorsData(next)"  type="button" class="ml-3 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Proximo</button>
    </div>
    </nav>
  </div>
  <LoadingItemVue
    v-if="loading"
  />
</template>

<script>

import MentorRepository from '@/services/MentorRepository.vue'
import LoadingItemVue from '../general_ui_items/LoadingItem.vue'

export default {
  name: 'MentorshipList',
  async created(){
    this.getMentorsData(this.defaultPage)
  },
  props: {
    
  },
  data(){
    return{
      onlineUser: JSON.parse(window.localStorage.getItem("onlineUser")),
      mentorsData: {},
      defaultPage: "https://aplication.doctorbrand.com.br/api/getMentorsWithImg?page=1",
      total:1,
      pageResults:1,
      currentPage:1,
      next:"",
      previous:"",
      loading: false,
    }
  },
  methods: {
   async getMentorsData(page){
      this.loading = true;
      const mentorRepo = new MentorRepository();
      try {
        const response = await mentorRepo.getMentorsWithImg(this.onlineUser.token, page);
        if (response?.status  === 200) {
          this.mentorsData = response.data;
          this.total = response.data.total;
          this.pageResults = response.data.per_page;
          this.currentPage = response.data.current_page;
          if (response.data.next_page_url) {
            this.next = response.data.next_page_url;
          }else{
            this.next = ""
          }
          if (response.data.prev_page_url) {
            this.previous = response.data.prev_page_url;
          }else{
            this.previous = ""
          }
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false; 
      }
    },
  },
  components:{
    LoadingItemVue
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
