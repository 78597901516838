<template>
    <MainFormVue/>
</template>

<script>
import MainFormVue from '../MainForm.vue'

export default {
  name: 'MentorCreate',
  async created(){
  },
  props: {
    
  },
  data(){
    return{
    }
  },
  methods:{
  },
  components:{
    MainFormVue
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
