<template>
  <div class="rounded-md bg-yellow-50 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-yellow-800">{{alertTitle}}</h3>
        <div class="mt-2 text-sm text-yellow-700">
          <p>{{alertText}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid'

export default {
    name: 'YellowAlertItem',
    props:{
        alertTitle:{
            type:String,
            required:true
        },
        alertText:{
            type:String,
            required:true
        },
    },
    components:{
        ExclamationTriangleIcon
    }
}
</script>

<style scoped>

</style>