<template>
  <div>
    <div class="sm:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
      <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" @change="redirectToTab">
        <option v-for="tab in tabs" :key="tab.name" :value="tab.link" :selected="tab.current">{{ tab.name }}</option>
      </select>
    </div>
    <div class="hidden sm:block">
      <nav class="-mb-px flex space-x-8" aria-label="Tabs">
        <div v-for="tab in tabs" :key="tab.name" :href="tab.href">
          <router-link :to="tab.link">
            <a @click="setCurrent(tab)" :class="[tab.current ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium']" :aria-current="tab.current ? 'page' : undefined">{{ tab.name }}</a>
          </router-link>
        </div>
      </nav>
    </div>
  </div>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'MainTemplate',
  data(){
    return {
      mentorsData: {},
    }
  },
  props: {
    tabs: {
        type:Array,
        required:true
    } 
  },
  methods:{
    setCurrent(clickedItem) {
      this.tabs.forEach(item => {
        item.current = item.id === clickedItem.id;
      });
    },
     redirectToTab(event) {
      const selectedTabLink = event.target.value;
      this.$router.push(selectedTabLink);
    },
  },
  components:{
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
