<template>
    <div class="bg-white">
        <!-- Header -->
        <header class="absolute inset-x-0 top-0 z-50">
            <nav class="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
            <div class="flex lg:flex-1">
                <a href="#" class="-m-1.5 p-1.5">
                <img class="h-8 logo" src="/doctorbrand_white_svg.svg" alt="DoctorBrand">
                </a>
            </div>
            <div class="flex lg:hidden">
                <button @click="MobileMenuHandler(true)" type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400">
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                </button>
            </div>
            <div class="hidden lg:flex lg:gap-x-12">
                <a href="#" class="text-sm font-semibold leading-6 text-white">Produtos</a>
                <a href="#" class="text-sm font-semibold leading-6 text-white">Recursos</a>
                <a href="#" class="text-sm font-semibold leading-6 text-white">Marketplace</a>
                <a href="#" class="text-sm font-semibold leading-6 text-white">Empresa</a>
                <a href="https://open.spotify.com/episode/6KEdoe1GgfWNaN1ytEtbXS?si=ATXccGo6SqWVzGg-FXDUKw" target="_blank" class="text-sm font-semibold leading-6 text-white">Podcast</a>
            </div>
            <div class="hidden lg:flex lg:flex-1 lg:justify-end">
                
                <a href="/login" class="text-sm font-semibold leading-6 text-white">Log in <span aria-hidden="true">&rarr;</span></a>
            </div>
            </nav>
            <!-- Mobile menu, show/hide based on menu open state. -->
            <div class="lg:hidden" role="dialog" aria-modal="true" v-if="openMobileMenu">
            <!-- Background backdrop, show/hide based on slide-over state. -->
            <div class="fixed inset-0 z-50"></div>
                <div class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div class="flex items-center justify-between">
                        <a href="#" class="-m-1.5 p-1.5">
                            <img class="h-8 w-auto" src="/doctorbrand_white_svg.svg" alt="DoctorBrand">
                        </a>
                        <button @click="MobileMenuHandler(false)" type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700">
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        </div>
                        <div class="mt-6 flow-root">
                        <div class="-my-6 divide-y divide-gray-500/10">
                            <div class="py-6">
                                <div class="space-y-2 py-6">
                                    <a href="#" class="text-sm font-semibold leading-6 text-white">Produtos</a>
                                    <a href="#" class="text-sm font-semibold leading-6 text-white">Recursos</a>
                                    <a href="#" class="text-sm font-semibold leading-6 text-white">Marketplace</a>
                                    <a href="#" class="text-sm font-semibold leading-6 text-white">Empresa</a>
                                    <a href="https://open.spotify.com/episode/6KEdoe1GgfWNaN1ytEtbXS?si=ATXccGo6SqWVzGg-FXDUKw" target="_blank" class="text-sm font-semibold leading-6 text-white">Podcast</a>
                                </div>
                            <a href="/login" class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log in</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <main>
            <!-- Hero section -->
            <div class="relative isolate overflow-hidden bg-gray-900 pb-16 pt-14 sm:pb-20">
            <img src="https://images.unsplash.com/photo-1588873281272-14886ba1f737?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA&auto=format&fit=crop&w=2650&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover">
            <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
                <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
            </div>
            <div class="mx-auto max-w-7xl px-6 lg:px-8">
                <div class="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
                    <div class="hidden sm:mb-8 sm:flex sm:justify-center">
                        <div class="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-400 ring-1 ring-white/10 hover:ring-white/20">
                        Promoções especiais para parceiros. <a href="/mentorSignUp" class="font-semibold text-white"><span class="absolute inset-0" aria-hidden="true"></span>Saiba mais <span aria-hidden="true">&rarr;</span></a>
                        </div>
                    </div>
                    <div class="text-center">
                        <h1 class="text-4xl font-bold tracking-tight text-white sm:text-6xl">Encontre o especialista certo para você.</h1>
                        <p class="mt-6 text-lg leading-8 text-gray-300">Receba orientação de especialistas em diversas áreas para o seu sucesso.</p>
                        <div class="mt-10 flex items-center justify-center gap-x-6">
                        <a href="/menteeSignUp" class="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">Começe Agora</a>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <p class="mt-6 text-lg leading-8 text-gray-300">Parceiros</p>
                </div>
                <!-- Logo cloud -->
                <div class="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                    <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/transistor-logo-white.svg" alt="Transistor" width="158" height="48">
                    <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/reform-logo-white.svg" alt="Reform" width="158" height="48">
                    <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/tuple-logo-white.svg" alt="Tuple" width="158" height="48">
                    <img class="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/savvycal-logo-white.svg" alt="SavvyCal" width="158" height="48">
                    <img class="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/statamic-logo-white.svg" alt="Statamic" width="158" height="48">
                </div>
            </div>
            <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
                <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
            </div>
            </div>

                <!-- Pricing section -->
                <div class="py-24 sm:pt-48">
                    <div class="mx-auto max-w-7xl px-6 lg:px-8">
                        <div class="mx-auto max-w-4xl text-center">
                            <h2 class="text-base font-semibold leading-7 text-indigo-600">Como funciona?</h2>
                            <p class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Encontre o especialista certo</p>
                        </div>
                        <p class="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">Os especialistas da Doctor Brand estão aqui pra fazer um checkup completo da sua carreira. Tudo para facilitar sua vida e alavancar seus resultados.  Faça como centenas de profissionais da área da saúde: agende uma mentoria agora.</p>
                        <div class="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                            <div class="flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10 lg:mt-8 lg:rounded-r-none">
                                <div>
                                    <div class="flex items-center justify-between gap-x-4">
                                        <h3 id="tier-freelancer" class="text-lg font-semibold leading-8 text-gray-900">01</h3>
                                    </div>
                                    <p class="mt-4 text-sm leading-6 text-gray-600">Contamos com uma rede de profissionais das mais diversas áreas e prontos para resolver o seu problema.</p>
                                    <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                                        <li class="flex gap-x-3">
                                        <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                        </svg>
                                        Mentoria Totalmente Online
                                        </li>
                                        <li class="flex gap-x-3">
                                        <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                        </svg>
                                        Profissionais altamente qualificados
                                        </li>
                                        <li class="flex gap-x-3">
                                        <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                        </svg>
                                        Mentoria Personalizada de acordo com sua necessidade
                                        </li>
                                        <li class="flex gap-x-3">
                                        <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                        </svg>
                                        Suporte em até 48 horas
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div class="flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10 lg:z-10 lg:rounded-b-none">
                                    <div>
                                    <div class="flex items-center justify-between gap-x-4">
                                        <h3 id="tier-startup" class="text-lg font-semibold leading-8 text-indigo-600">02</h3>
                                    </div>
                                    <p class="mt-4 text-sm leading-6 text-gray-600">Com poucos cliques você agenda o dia e a hora da sua mentoria. É simples, rápido e 100% online.</p>
                                    <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                                        <li class="flex gap-x-3">
                                        <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                        </svg>
                                        Ambiente de compra totalmente seguro
                                        </li>
                                        <li class="flex gap-x-3">
                                        <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                        </svg>
                                        Seguimos a normas da LGPD seus dados estaram em segurança conosco
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div class="flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10 lg:mt-8 lg:rounded-l-none">
                                    <div>
                                    <div class="flex items-center justify-between gap-x-4">
                                        <h3 id="tier-enterprise" class="text-lg font-semibold leading-8 text-gray-900">03</h3>
                                    </div>
                                    <p class="mt-4 text-sm leading-6 text-gray-600">Avalie o especialista de acordo com seus resultados.</p>
                                    <p class="mt-4 text-sm leading-6 text-gray-600">Sua avaliação é fundamental para garantir o alto nível técnico dos nossos especialistas.</p>
                                    <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                                        <li class="flex gap-x-3">
                                        <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                        </svg>
                                        Avaliação de Usuários: Garantimos qualidade com base nas avaliações.
                                        </li>
                                        <li class="flex gap-x-3">
                                        <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                        </svg>
                                        Assinantes Ilimitados: Desfrute de nossa plataforma sem limites.
                                        </li>
                                        <li class="flex gap-x-3">
                                        <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                        </svg>
                                        Analytics Avançada: Obtenha insights aprofundados.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="text-center">
                    <h1 class="text-4xl font-bold tracking-tight text-black sm:text-6xl">Apresentamos alguns dos notáveis especialistas.</h1>
                </div>

                <!-- Testimonial section -->
                <div class="relative z-10 mt-32 bg-gray-900 pb-20 sm:mt-56 sm:pb-24 xl:pb-0">
                    <div class="absolute inset-0 overflow-hidden" aria-hidden="true">
                        <div class="absolute left-[calc(50%-19rem)] top-[calc(50%-36rem)] transform-gpu blur-3xl">
                        <div class="aspect-[1097/1023] w-[68.5625rem] bg-gradient-to-r from-[#ff4694] to-[#776fff] opacity-25" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
                        </div>
                    </div>
                    <div class="mx-auto flex max-w-7xl flex-col items-center gap-x-8 gap-y-10 px-6 sm:gap-y-8 lg:px-8 xl:flex-row xl:items-stretch">
                        <div class="-mt-8 w-full max-w-2xl xl:-mb-8 xl:w-96 xl:flex-none">
                            <div class="relative aspect-[2/1] h-full md:-mx-8 xl:mx-0 xl:aspect-auto">
                                <img class="absolute inset-0 h-full w-full rounded-2xl bg-gray-800 object-cover shadow-2xl" src="https://uploads-ssl.webflow.com/60612d0857d00c7b770bd03a/6061448b59cb0e1dbdf5d117_1598132417084.jpg" alt="Adriano Oliveira">
                            </div>
                        </div>
                        <div class="w-full max-w-2xl xl:max-w-none xl:flex-auto xl:px-16 xl:py-24">
                            <figure class="relative isolate pt-6 sm:pt-12">
                                <svg viewBox="0 0 162 128" fill="none" aria-hidden="true" class="absolute left-0 top-0 -z-10 h-32 stroke-white/20">
                                <path id="b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb" d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z" />
                                <use href="#b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb" x="86" />
                                </svg>
                                <blockquote class="text-xl font-semibold leading-8 text-white sm:text-2xl sm:leading-9">
                                <p>Profissional com 20 anos de experiência na indústria farmacêutica, com formação em Administração de Empresas e MBA em Gestão Empresarial.</p>
                                </blockquote>
                                <figcaption class="mt-8 text-base">
                                <div class="font-semibold text-white">Adriano Oliveira</div>
                                <div class="mt-1 text-gray-400">Executivo da Indústria Farmacêutica</div>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
                <!-- Testimonial section -->
                <div class="relative z-10 mt-32 bg-gray-900 pb-20 sm:mt-56 sm:pb-24 xl:pb-0">
                    <div class="absolute inset-0 overflow-hidden" aria-hidden="true">
                        <div class="absolute left-[calc(50%-19rem)] top-[calc(50%-36rem)] transform-gpu blur-3xl">
                        <div class="aspect-[1097/1023] w-[68.5625rem] bg-gradient-to-r from-[#ff4694] to-[#776fff] opacity-25" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
                        </div>
                    </div>
                    <div class="mx-auto flex max-w-7xl flex-col items-center gap-x-8 gap-y-10 px-6 sm:gap-y-8 lg:px-8 xl:flex-row xl:items-stretch">
                        <div class="-mt-8 w-full max-w-2xl xl:-mb-8 xl:w-96 xl:flex-none">
                            <div class="relative aspect-[2/1] h-full md:-mx-8 xl:mx-0 xl:aspect-auto">
                                <img class="absolute inset-0 h-full w-full rounded-2xl bg-gray-800 object-cover shadow-2xl" src="https://uploads-ssl.webflow.com/60612d0857d00c7b770bd03a/608316942586074357c1189a_99368_1000x600_width-1070794.jpg" alt="Dra. Sandra Franco">
                            </div>
                        </div>
                        <div class="w-full max-w-2xl xl:max-w-none xl:flex-auto xl:px-16 xl:py-24">
                            <figure class="relative isolate pt-6 sm:pt-12">
                                <svg viewBox="0 0 162 128" fill="none" aria-hidden="true" class="absolute left-0 top-0 -z-10 h-32 stroke-white/20">
                                <path id="b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb" d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z" />
                                <use href="#b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb" x="86" />
                                </svg>
                                <blockquote class="text-xl font-semibold leading-8 text-white sm:text-2xl sm:leading-9">
                                <p>Apaixonada pelo Direito Médico e da Saúde, a Dra. Sandra Franco é uma das mais requisitadas advogadas do Brasil.</p>
                                </blockquote>
                                <figcaption class="mt-8 text-base">
                                <div class="font-semibold text-white">Dra. Sandra Franco</div>
                                <div class="mt-1 text-gray-400">Advogada</div>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
                <!-- Testimonial section -->
                <div class="relative z-10 mt-32 bg-gray-900 pb-20 sm:mt-56 sm:pb-24 xl:pb-0">
                    <div class="absolute inset-0 overflow-hidden" aria-hidden="true">
                        <div class="absolute left-[calc(50%-19rem)] top-[calc(50%-36rem)] transform-gpu blur-3xl">
                        <div class="aspect-[1097/1023] w-[68.5625rem] bg-gradient-to-r from-[#ff4694] to-[#776fff] opacity-25" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
                        </div>
                    </div>
                    <div class="mx-auto flex max-w-7xl flex-col items-center gap-x-8 gap-y-10 px-6 sm:gap-y-8 lg:px-8 xl:flex-row xl:items-stretch">
                        <div class="-mt-8 w-full max-w-2xl xl:-mb-8 xl:w-96 xl:flex-none">
                            <div class="relative aspect-[2/1] h-full md:-mx-8 xl:mx-0 xl:aspect-auto">
                                <img class="absolute inset-0 h-full w-full rounded-2xl bg-gray-800 object-cover shadow-2xl" src="https://uploads-ssl.webflow.com/60612d0857d00c7b770bd03a/609283140e7bba7faa37e805_flavio.jpg" alt="Flavio Cavalcante">
                            </div>
                        </div>
                        <div class="w-full max-w-2xl xl:max-w-none xl:flex-auto xl:px-16 xl:py-24">
                            <figure class="relative isolate pt-6 sm:pt-12">
                                <svg viewBox="0 0 162 128" fill="none" aria-hidden="true" class="absolute left-0 top-0 -z-10 h-32 stroke-white/20">
                                <path id="b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb" d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z" />
                                <use href="#b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb" x="86" />
                                </svg>
                                <blockquote class="text-xl font-semibold leading-8 text-white sm:text-2xl sm:leading-9">
                                <p>Mais de 10 mil empreendimentos tiveram a minha ajuda e mais 600 mil profissionais. Também sou escritor, palestrante e professor da FGV.</p>
                                </blockquote>
                                <figcaption class="mt-8 text-base">
                                <div class="font-semibold text-white">Flavio Cavalcante</div>
                                <div class="mt-1 text-gray-400">CEO Fundador - Target School</div>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>

        </main>

        <!-- Footer -->
        <footer class="mt-32 bg-gray-900 sm:mt-56" aria-labelledby="footer-heading">
            <h2 id="footer-heading" class="sr-only">Footer</h2>
            <div class="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8 lg:py-32">
            <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                <img class="h-7 logo" src="/doctorbrand_white_svg.svg" alt="DoctorBrand">
                <div class="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
                <div class="md:grid md:grid-cols-2 md:gap-8">
                    <div>
                    <h3 class="text-sm font-semibold leading-6 text-white">Soluções</h3>
                    <ul role="list" class="mt-6 space-y-4">
                        <li>
                        <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Marketing</a>
                        </li>
                        <li>
                        <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Mentorias</a>
                        </li>
                        <li>
                        <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">E-Commerce</a>
                        </li>
                        <li>
                        <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Insights</a>
                        </li>
                    </ul>
                    </div>
                    <div class="mt-10 md:mt-0">
                    <h3 class="text-sm font-semibold leading-6 text-white">Suporte</h3>
                    <ul role="list" class="mt-6 space-y-4">
                        <li>
                        <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Acesso</a>
                        </li>
                        <li>
                        <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Cobrança</a>
                        </li>
                    </ul>
                    </div>
                </div>
                <div class="md:grid md:grid-cols-2 md:gap-8">
                    <div>
                    <h3 class="text-sm font-semibold leading-6 text-white">Company</h3>
                    <ul role="list" class="mt-6 space-y-4">
                        <li>
                        <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Sobre</a>
                        </li>
                        <li>
                        <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Blog</a>
                        </li>
                        <li>
                        <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Vagas</a>
                        </li>
                        <li>
                        <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Impressa</a>
                        </li>
                        <li>
                        <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Parceiros</a>
                        </li>
                    </ul>
                    </div>
                    <div class="mt-10 md:mt-0">
                    <h3 class="text-sm font-semibold leading-6 text-white">Legal</h3>
                    <ul role="list" class="mt-6 space-y-4">
                        <li>
                        <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Reivindicação Legal</a>
                        </li>
                        <li>
                        <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Privacidade</a>
                        </li>
                        <li>
                        <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Termos</a>
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </footer>
        </div>

</template>

<script>
export default {

  name:'GatewayView',
  data(){
    return{
    openMobileMenu: false
    }
  },
  components:{
  },
  async created(){
  },
  methods:{
    MobileMenuHandler(state){
        this.openMobileMenu = state;
    }
  }

}
</script>

<style scoped>
.logo{
    width: 300px;
}
</style>