<template>
    <MenteeRegistrationFormVue/>
</template>

<script>
import MenteeRegistrationFormVue from '../MenteeRegistrationForm.vue'


export default {
  name: 'MentorCreate',
  async created(){
  },
  props: {
    
  },
  data(){
    return{
    }
  },
  methods:{
  },
  components:{
    MenteeRegistrationFormVue
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
