<template>
  <html class="h-full bg-white">
    <body class="h-full">
        <LoginFormVue/>
      <!-- Conteúdo do seu componente -->
    </body>
  </html>
</template>

<script>

import LoginFormVue from '@/components/LoginForm.vue';

export default {
    name: 'LoginView',
    components:{
        LoginFormVue
    }
}
</script>

<style scoped>
</style>