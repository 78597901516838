import { createRouter, createWebHistory } from 'vue-router'

import LoginView from '@/views/LoginView';
import DashboardView from '@/views/DashboardView';
import MentorRoot from '@/components/mentor/MentorRoot';
import MentorList from '@/components/mentor/MentorList'
import MentorCreate from '@/components/mentor/MentorCreate'
import NotFound from '@/components/NotFoundPage';
import MenteesRoot from '@/components/mentees/MenteesRoot';
import MenteesList from '@/components/mentees/MenteesList';
import MenteesCreate from '@/components/mentees/MenteesCreate';
import MenteesProfile from '@/components/mentees/MenteesProfile';
import MentorshipList from '@/components/mentorship/MentorshipList';
import MentorshipSchedule from '@/components/mentorship/MentorshipSchedule';
import MyMentorshipList from '@/components/user_mentoships/MyMentorshipList';
import MyMentorshipListMentor from '@/components/user_mentoships/MyMentorshipListMentor';
import GatewayView from '@/views/GatewayView';
import MenteeSignUp from '@/components/sign_in/MenteeSignUp';
import MentorSignUp from '@/components/sign_in/MentorSignUp';
import HomeRoot from '@/components/home/HomeRoot'

const routes = [
  {
    path: '/',
    name: 'landing',
    component: GatewayView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/menteeSignUp',
    name: 'menteeReg',
    component: MenteeSignUp
  },
  {
    path: '/mentorSignUp',
    name: 'mentorReg',
    component: MentorSignUp
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    redirect:'/home',
    children:[
      {
        path: '/mentors',
        component: MentorRoot,
        redirect:'/mentor/list',
        children:[
          {
            path:'/mentor/list',
            component: MentorList,
          },
          {
            path:'/mentor/create',
            component: MentorCreate,
          },
        ]
      },
      {
        path: '/mentee',
        component: MenteesRoot,
        redirect:'/mentee/list',
        children:[
          {
            path:'/mentee/list',
            component: MenteesList,
          },
          {
            path:'/mentee/create',
            component: MenteesCreate,
          }
        ]
      },
      {
        path: '/home',
        name: 'home',
        component: HomeRoot
      }
      ,
      {
        path: '/mentorship/list',
        name: 'mentoshiplist',
        component: MentorshipList
      }
      ,
      {
        path: '/mentorship/schedule/:id',
        name: 'schedule',
        component: MentorshipSchedule
      }
      ,
      {
        path: '/mentorship/myMentorships',
        name: 'myMentorship',
        component: MyMentorshipList
      }
      ,
      {
        path: '/mentorship/myMentorshipsMentor',
        name: 'myMentorshipMentor',
        component: MyMentorshipListMentor
      },
      {
        path:'/mentee/profile/:id',
        component: MenteesProfile,
      },
    ]
  },
  {
    path:'/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
