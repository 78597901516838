<template>
  <MainTemplateVue
    :tabs="tabs"
  />
</template>

<script>

import MainTemplateVue from '../templates/MainTemplate.vue'

export default {
  name: 'MenteesRoot',
  data(){
    return {
      mentorsData: {},
      tabs : [
        { name: 'listar', href: '#', current: true , link:'/mentee/list', id: 1 },
        { name: 'Novo Registro', href: '#',  current: false , link: '/mentee/create',id: 2 },
      ],
      onlineUser: JSON.parse(window.localStorage.getItem("onlineUser")),
    }
  },
  props: {
    
  },
  methods:{
    
  },
  components:{
    MainTemplateVue
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
