<template>
  <div ><MenteeRegistrationFormIntern /></div>
  <div v-if="onlineUser.type == 1"><MentorUpdateForm /></div>
  
  
</template>

<script>
import MenteeRegistrationFormIntern from '../MenteeRegistrationFormIntern.vue'
import MentorUpdateForm from '../MentorUpdateForm.vue'

export default {
  name: 'MenteesProfile',
  async created(){
    this.setUser()
  },
  props: {
    
  },
  data(){
    return{
      tab: 1,
      onlineUser: JSON.parse(window.localStorage.getItem("onlineUser")),
    }
  },
  methods:{
    setUser(){
      if(this.onlineUser.type == 2){
        this.tab = 2
      }
    }
  },
  components:{
    MenteeRegistrationFormIntern,
    MentorUpdateForm
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
