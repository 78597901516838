<template>
  <div class="min-h-full">
    <Popover as="header" class="bg-indigo-600 pb-24" v-slot="{ open }">
      <div class="mx-auto max-w-3xl px-4 py-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div class="relative flex items-center justify-center py-5 lg:justify-between">
          <!-- Logo -->
          <div class="flex absolute left-0 flex-shrink-0 lg:static">
            <a href="#">
              <img class="h-8 logo" src="/doctorbrand_white_svg.svg" alt="DoctorBrand" />
            </a>
            <span class="text-white">Olá  {{onlineUser.name}} seja bem vindo novamente</span>
          </div>

          <!-- Right section on desktop -->
          <div class="hidden lg:ml-4 lg:flex lg:items-center lg:pr-0.5">
            <button type="button" class="flex-shrink-0 rounded-full p-1 text-indigo-200 hover:bg-white hover:bg-opacity-10 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
              <span class="sr-only">Notificações</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button>

            <!-- Profile dropdown -->
            <Menu as="div" class="relative ml-4 flex-shrink-0">
              <div>
                <MenuButton class="flex rounded-full bg-white text-sm ring-2 ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100">
                  <span class="sr-only">Menu de Usuário</span>
                  <img class="h-8 w-8 rounded-full" :src="'data:image/png;base64,' + onlineUser.image" :alt="onlineUser.name"/>
                </MenuButton>
              </div>
              <transition leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="absolute -right-2 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                    <a :href="item.href" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">{{ item.name }}</a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>

          <!-- Menu button -->
          <div class="absolute right-0 flex-shrink-0 lg:hidden">
            <!-- Mobile menu button -->
            <PopoverButton class="inline-flex items-center justify-center rounded-md bg-transparent p-2 text-indigo-200 hover:bg-white hover:bg-opacity-10 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
              <span class="sr-only">Menu Principal</span>
              <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </PopoverButton>
          </div>
        </div>
        <div class="hidden border-t border-white border-opacity-20 py-5 lg:block">
          <div class="grid grid-cols-3 items-center gap-8">
            <div class="col-span-2">
              <nav class="flex space-x-4">
                <div v-for="item in navigation" :key="item.name" :href="item.href">
                  <router-link :to="item.link">
                    <a  @click="setCurrent(item)"  :class="[item.current ? 'text-white bg-opacity-10' : 'text-indigo-100', 'rounded-md bg-white bg-opacity-0 px-3 py-2 text-sm font-medium hover:bg-opacity-10']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>
                  </router-link>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <TransitionRoot as="template" :show="open">
        <div class="lg:hidden">
          <TransitionChild as="template" enter="duration-150 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-150 ease-in" leave-from="opacity-100" leave-to="opacity-0">
            <PopoverOverlay class="fixed inset-0 z-20 bg-black bg-opacity-25" />
          </TransitionChild>

          <TransitionChild as="template" enter="duration-150 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-150 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
            <PopoverPanel focus class="absolute inset-x-0 top-0 z-30 mx-auto w-full max-w-3xl origin-top transform p-2 transition">
              <div class="divide-y divide-gray-200 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div class="pb-2 pt-3">
                  <div class="flex items-center justify-between px-4">
                    <div>
                      <img class="h-8 w-auto" src="https://uploads-ssl.webflow.com/60607b25535705ee2df91fbf/606083395c9d2b42f33c3309_logotipo.svg" alt="DoctorBrand" />
                    </div>
                    <div class="-mr-2">
                      <PopoverButton class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span class="sr-only">Fechar</span>
                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                      </PopoverButton>
                    </div>
                  </div>
                  <div v-for="item in navigation" :key="item.name" :href="item.href" class="mt-3 space-y-1 px-2">
                    <router-link :to="item.link">
                      <a href="#" class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800">{{ item.name }}</a>
                    </router-link>
                  </div>
                </div>
                <div class="pb-2 pt-4">
                  <div class="flex items-center px-5">
                    <div class="flex-shrink-0">
                      <img class="h-10 w-10 rounded-full" :src="'data:image/png;base64,' + onlineUser.image" :alt="onlineUser.name"/>
                    </div>
                    <div class="ml-3 min-w-0 flex-1">
                      <div class="truncate text-base font-medium text-gray-800">{{ onlineUser.name }}</div>
                      <div class="truncate text-sm font-medium text-gray-500">{{ onlineUser.email }}</div>
                    </div>
                    <button type="button" class="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span class="sr-only">Ver Notificações</span>
                      <BellIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="mt-3 space-y-1 px-2">
                    <a v-for="item in userNavigation" :key="item.name" :href="item.href" class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800">{{ item.name }}</a>
                  </div>
                </div>
              </div>
            </PopoverPanel>
          </TransitionChild>
        </div>
      </TransitionRoot>
    </Popover>
    <main class="-mt-24 pb-8">
      <div class="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h1 class="sr-only">Page title</h1>
        <!-- Main 3 column grid -->
        <div class="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
          <!-- Left column -->
          <div class="grid grid-cols-1 gap-4 lg:col-span-3">
            <section aria-labelledby="section-1-title">
              <h2 class="sr-only" id="section-1-title">Section title</h2>
              <div class="overflow-hidden rounded-lg bg-white shadow">
                <div class="p-6">
                  <!-- Your content -->
                  <router-view></router-view>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </main>
    <footer>
      <div class="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div class="border-t border-gray-200 py-8 text-center text-sm text-gray-500 sm:text-left"><span class="block sm:inline">&copy; 2021 Doctor Brand, Inc.</span> <span class="block sm:inline">All rights reserved.</span></div>
      </div>
    </footer>
  </div>
</template>

<script>

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline'

export default {

  name:'DashboardView',
  data(){
      return{
          navigation : [
            { name: 'Home', href: '#', current: true, id: 1 ,link:"/home"},
            { name: 'Perfil', href: '#', current: false, id: 2 ,link:"/mentors"},
            { name: 'Mentores', href: '#', current: false, id: 3 ,link:"/mentors"},
            { name: 'Mentorados', href: '#', current: false, id: 4,link:"/mentee"},
          ],
          onlineUser: JSON.parse(window.localStorage.getItem("onlineUser")),
          userNavigation: [
            { name: 'Perfil', href: '#' },
            { name: 'Configurações', href: '#' },
            { name: 'Sair', href: '/login' },
          ]
      }
  },
  components:{
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Popover,
    PopoverButton,
    PopoverOverlay,
    PopoverPanel,
    TransitionChild,
    TransitionRoot,
    Bars3Icon, 
    BellIcon, 
    XMarkIcon
  },
  async created(){
    this.realTabs();
  },
  methods:{
    setCurrent(clickedItem) {
      this.navigation.forEach(item => {
        item.current = item.id === clickedItem.id;
      });
    },
    realTabs(){
      if (this.onlineUser.type === 2) {
        this.navigation =  [
            { name: 'Home', href: '#', current: true, id: 1 ,link:"/home"},
            { name: 'Perfil', href: '#', current: false, id: 2 ,link:`/mentee/profile/${this.onlineUser.id}`},
            { name: 'Mentorias', href: '#', current: false, id: 3 ,link:"/mentorship/list"},
            { name: 'Minhas Mentorias', href: '#', current: false, id: 4,link:"/mentorship/myMentorships"},
          ]
      }
      if (this.onlineUser.type === 1) {
        this.navigation =  [
            { name: 'Home', href: '#', current: true, id: 1 ,link:"/home"},
            { name: 'Perfil', href: '#', current: false, id: 2 ,link:`/mentee/profile/${this.onlineUser.id}`},
            { name: 'Minhas Mentorias', href: '#', current: false, id: 4,link:"/mentorship/myMentorshipsMentor"},
          ]
      }
    }
  }

}
</script>

<style scoped>
.logo{
  width: 300px;
}
/* Estilos do componente */
</style>