<script>

import axios from 'axios';

export default class LoginRepository{
    
    async doLogin(user){
        
        const baseURL = "https://aplication.doctorbrand.com.br/api/login";
        const headers = {
            'Content-Type':'application/json',
            'Accept':'application/json'
        };
        const data = {
            email: user.email,
            password: user.password
        };
        const response = await axios.post(baseURL, data, {headers:headers});
        return response;

    }

}
</script>
